<template>
  <div class="home-wrapper">
    <div class="game-wrapper">
      <div
        class="game-card"
        v-for="(item, index) in searchData"
        :key="index"
        @click="toDetail(item)"
      >
        <a
          :href="`/detail?id=${item.ct_id}&category=${
            item.ct_name
          }&description=${item.description}&instructions=${
            item.instructions ? item.instructions : ''
          }&tags=${item.tags}&thumb=${item.img}&title=${item.title}&url
=${item.game_url}`"
        >
          <img :src="item.banner" alt="" />
        </a>
      </div>
    </div>
    <button
      style="
        width: 80px;
        height: 34px;
        color: #ffffff;
        background: #474747;
        cursor: pointer;
      "
      @click="clar"
    >
      Go Back
    </button>
  </div>
</template>

<script>
import FooterBar from "../components/FooterBar.vue";
import CookieAccept from "../components/CookieAccept";
import { initGameList } from "../api";
import CookieAcceptVue from "@/components/CookieAccept.vue";
export default {
  props: {
    searchData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  methods: {
    // initData(query) {
    //   initGameList(query).then((res) => {
    //     this.gameList = res;
    //   });
    // },
    // handleScroll() {
    //   const wrap = document.querySelector('.game-wrapper')
    //   if (window.scrollY + window.innerHeight + 20 > wrap.offsetHeight + wrap.offsetTop && !this.isLoading) {
    //     this.isLoading = true;
    //     this.page++;
    //     initGameList({ num: 25, page: this.page }).then((res) => {
    //       console.log(res)
    //       this.gameList = this.gameList.concat(res);
    //       this.isLoading = false
    //     }).catch(e => {
    //       this.isLoading = false
    //     })
    //   }

    // },
    clar() {
      this.$emit("clar", []);
    },
    toDetail(row) {
      console.log("row", row);
      // this.$router.push({
      //   name: "detail",
      //   query: { id: row.id },
      //   params: { info: row },
      // });
      // this.$router.go(0);
    },
  },
  created() {},
  mounted() {
    //添加滚动事件
  },
};
</script>

<style scoped>
.home-wrapper {
  background: var(--blue);
  padding-bottom: 60px;
  text-align: center;
}

.game-wrapper {
  /* width: 100vw;
  min-height: 100vh; */
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 20px 0px 25px 15px;
  background: var(--blue);
}

.game-card {
  width: 120px;
  height: 80px;
  overflow: hidden;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  transition: 0.3s;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}

.game-card:hover {
  transform: rotate(2deg) scale(0.95);
}

.game-card img {
  width: 100%;
  height: auto;
}

@media (max-width: 600px) {
  .game-wrapper {
    width: 100vw;
    /* min-height: 100vh; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;
    background: var(--blue);
  }

  .game-card {
    width: 115px;
    height: 80px;
    overflow: hidden;
    border-radius: 8px;
    margin-right: 0;
    margin-bottom: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  }

  .game-card img {
    width: 100%;
    height: auto;
  }

  @media (min-width: 100px) and (max-width: 400px) {
    .game-card {
      width: 31.5%;
      height: 75px;
      overflow: hidden;
      border-radius: 8px;
      margin-right: 0;
      margin-bottom: 12px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    }

    .game-card img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
