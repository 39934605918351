<template>
    <div class="footer-wrapper">
        <p>
            We use cookies to improve your experience on our website. By using this site you agree to our use of cookies. 
            <router-link to="/privacy">Learn more</router-link>
        </p>
        <div @click="handleAgree" class="agree">Agree</div>
    </div>
</template>

<script>
    import { setCookieAccept } from '../utils/storage';
    export default {
        methods: {
            handleAgree() {
                setCookieAccept()
            }
        }
    }
</script>

<style scoped>
.footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    position: fixed;
    padding: 5px 20px;
    width: 100%;
    bottom: 0;
    z-index: 999;
    left: 0;
    color: white;
    background: rgba(0,0,0,0.9);
}

a {
    color: rgb(238, 105, 39);
}

.agree {
    border-radius: 15px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: white;
    font-weight: bold;
    padding: 0 15px;
    background: #2faafa;
    cursor: pointer;
}

.agree:hover {
    background: #189cf4;
}
</style>
