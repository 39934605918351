<template>
  <!-- 头部导航栏 -->
  <div>
    <div class="header-wrapper">
      <div class="logo-wrapper">
        <a href="/">
          <img :src="logo" />
        </a>
      </div>

      <ul class="category-list">
        <li>
          <input v-model="selectName" />
          <img
            @click="selectNameClick"
            style="width: 20px; vertical-align: middle"
            src="../assets/serch.png"
            alt=""
          />
        </li>
        <li v-for="(item, index) in category" :key="index">
          <span @click="toCategory(item)">{{ item }}</span>
        </li>
      </ul>
      <ul :class="[visible ? 'md-category-list active' : 'md-category-list']">
        <li v-for="(item, index) in category" :key="index">
          <span @click="toCategory(item)">{{ item }}</span>
        </li>
      </ul>
      <div class="toggleBox">
        <div
          class="toggle"
          :style="{ transform: visible ? 'rotate(90deg)' : '' }"
          @click="toggleNav"
        >
          <img :src="toggleIcon" alt="no icon" />
        </div>
        <div class="toggle" @click="selectNameClickShow">
          <img
            style="width: 27px; vertical-align: middle"
            src="../assets/serch.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="selectBox" v-if="selectShow" style="color: azure">
      <div style="width: 80%">
        <el-input v-model="selectName" placeholder="Please enter"></el-input>
      </div>
      <div
        style="z-index: 9999; text-align: center; line-height: 40px; width: 20%"
        class="GoTE"
        @click="selectNameClick"
      >
        Go
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    visible: false,
    logo: require("../assets/Mangopro2.png"),
    toggleIcon: require("../assets/menu.png"),
    category: [
      "Arcade",
      ".IO",
      "Action",
      "Puzzles",
      "Racing",
      "Shooting",
      "Sports",
    ],
    selectName: "",
    selectShow: false,
  }),
  methods: {
    selectNameClickShow() {
      this.selectShow = !this.selectShow;
    },
    selectNameClick() {
      let d = "w_p=h5check&name=Arcade&source=bestgame&page=1&size=10";
      axios
        .post("https://gagame2.appclicking.com/api.php?req=GameSearch", d)
        .then((res) => {});
      if (this.selectName) {
        this.$emit("selectNameClick", this.selectName);
        this.selectName = "";
        this.selectShow = !this.selectShow;
      }
    },
    toCategory(param) {
      if (param == this.$route.query.id) {
        this.visible = false;
        return;
      }
      // this.$router.push({ path: '/category', query: { id: param } })
      location.href = "/category?id=" + param;
      this.visible = false;
    },
    toggleNav() {
      this.visible = !this.visible;
    },
    goHome() {
      if (this.$route.path == "/") return;
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.header-wrapper {
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #333333;
  padding: 0 20px;
  z-index: 10;
  opacity: 0.9;
  /* background-image: 
            radial-gradient(at 10% 10%, #000000 0, transparent 100%), 
            radial-gradient(at 67% 20%, hsla(357,94%,71%,1) 0, transparent 59%), 
            radial-gradient(at 88% 35%, hsla(222,81%,65%,1) 0, transparent 50%),  
            radial-gradient(at 53% 75%, hsla(174,94%,68%,1) 0, transparent 60%); */
}

.category-list {
  display: flex;
  color: white;
  font-weight: bold;
  align-items: center;
  height: 100%;
}

.logo-text {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.toggle {
  color: white;
  display: none;
  transition: 0.3s;
}

.category-list span {
  display: block;
  line-height: 60px;
  color: white;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
}

.logo-wrapper {
  padding: 10px 0;
  height: 60px;
  line-height: 60px;
}

.logo-wrapper img {
  height: 40px;
  margin-top: 3px;
}

.category-list span:hover {
  background: rgb(35, 33, 33);
}

.md-category-list {
  position: fixed;
  right: 20px;
  top: 50px;
  padding: 10px 35px;
  display: flex;
  height: auto;
  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.75);
  flex-direction: column;
  z-index: 10;
  opacity: 0;
  border-radius: 10px;
  transform: translateX(100px);
}

/* 响应式 */
@media (max-width: 600px) {
  .selectBox {
    display: flex;
    margin: 10px 0;

    /* justify-content: space-between; */
  }

  .GoTE {
    background-color: #474747;
    font-weight: 800;
    font-size: 20px;
  }

  .toggleBox {
    width: 70px;
    display: flex;
    justify-content: space-between;
  }

  .header-wrapper {
    justify-content: space-between;
  }

  .category-list {
    display: none;
  }

  .active {
    z-index: 99999;
    opacity: 1;
    transform: translateX(0px);
    animation: scroll 0.3s ease;
  }

  @keyframes scroll {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .md-category-list span {
    height: 30px;
    font-weight: bold;
    line-height: 30px;
    color: white;
  }

  .toggle {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .toggle:hover {
    opacity: 0.6;
  }

  .toggle img {
    height: 30px;
    width: 30px;
    vertical-align: middle;
  }
}
</style>
