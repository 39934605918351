<template>
    <div>
        <!-- v-if="accepted" -->
        <div class="footer-wrapper" v-if="accepted">

            <div>

            </div>
            <div>
                <p><a href="mailto:heyinfo@mangopro.com" target="blank">Contact Us</a> | <router-link to="/about">About Us</router-link> | <router-link to="/privacy">Privacy Policy</router-link></p>
                <p><router-link to="/terms">Terms and Conditions</router-link> | weenters.com © 2023</p>
            </div>
        </div>
        <div class="footer-wrapper" v-else style="flex-direction: row;padding: 5px 10px;">

            <div class="TWObOX">
                <p>
                    We use cookies to improve your experience on our website. By using this site you agree to our use of cookies.
                    <router-link to="/privacy" style="color: orange;">Learn more</router-link>
                </p>
                <div @click="handleAgree" class="agree">Agree</div>
            </div>
        </div>
    </div>
</template>

<script>
import { setCookieAccept, getCookieAccept } from '../utils/storage';
export default {
    data: () => ({
        accepted: false
    }),
    methods: {
        handleAgree() {
            this.accepted = true;
            setCookieAccept()
        }
    },
    created() {
        this.accepted = getCookieAccept()
    },
    mounted() {
        // googletag.cmd.push(function () { googletag.display('div-gpt-ad-1678248121556517-0'); });

    }
    // module() {
    //     googletag.cmd.push(function () { googletag.display('div-gpt-ad-1678248121556517-0'); });

    // }
}
</script>

<style scoped>
@media (min-width: 699px) {
    .footer-wrapper {
        text-align: center;
        /* display: flex;
        align-items: center;
        justify-content: space-between; */
        flex-direction: row;
        position: fixed;
        font-size: 14px;
        padding: 5px 20px;
        width: 100%;
        bottom: 0;
        left: 0;
        color: white;
        background: #333333;
    }

    .agree {
        border-radius: 15px;
        width: 80px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: white;
        font-weight: bold;
        padding: 0 15px;
        background: #2faafa;
        cursor: pointer;
    }

    .TWObOX {
        display: flex;
        justify-content: center;
    }
}

a {
    color: white;
}



.agree:hover {
    background: #189cf4;
}

@media (max-width: 700px) {
    .footer-wrapper {
        /* display: flex;
        flex-flow: column;

        align-items: center;
    
        flex-direction: row;
        position: fixed;
        font-size: 14px;
        padding: 5px 20px;
     
        bottom: 0;
        left: 0;
        color: white;
        */
        /* position: sticky; */
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        background: #333333;
        color: white;
    }

    .agree {
        border-radius: 15px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: white;
        font-weight: bold;
        padding: 0 15px;
        background: #2faafa;
        cursor: pointer;
    }
}
</style>
