import axios from 'axios';
/**
 * @description axios请求函数封装
 * @param {Object} config { url,headers,method...}
 * @return {Promise}
 * */
const request = function(config = {}) {
    const instance = axios.create({
        baseURL: 'https://gamemonetize.com/feed.php',
        timeout: 10000,
    });

    instance.interceptors.request.use(
        async function(config) {
            // 在发送请求之前做些什么
            if (!config.isJson) {
                config.headers['Content-type'] = 'application/json';
            }
            if (config.query) {
                var qr = '?';
                Object.keys(config.query).forEach((key) => {
                    qr += `${key}=${config.query[key]}&`;
                });
                config.url += qr.substring(0, qr.length - 1);
            }
            return config;
        },
        function(error) {
            return Promise.reject(error);
        }
    );

    // 添加响应拦截器
    instance.interceptors.response.use(
        function(response) {
            return response.data;
        },
        function(error) {
            // 超出 2xx 范围的状态码都会触发该函数。
            // 对响应错误做点什么
            return Promise.reject(error);
        }
    );

    return instance(config);
};

export default request;