import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [{
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/detail',
        name: 'detail',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/GameDetail.vue'),
    },
    {
        path: '/details',
        name: 'details',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/GameDetails.vue'),
    },
    {
        path: '/category',
        name: 'category',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/CategoryInfo.vue'),
    },
    {
        path: '/gamefull',
        name: 'gamefull',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/GameFull.vue'),
    },

    {
        path: '/privacy',
        name: 'privacy',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Privacy.vue'),
    },
    {
        path: '/terms',
        name: 'terms',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Terms.vue'),
    },
    {
        path: '/about',
        name: 'about',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/AboutUs.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;