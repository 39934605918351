<template>
  <div class="home-wrapper">
    <Navbar @selectNameClick="selectNameClick" />
    <search
      v-if="searchData.length > 0"
      :searchData="searchData"
      @clar="clar"
    />
    <div v-else style="height: 100vh">
      <div class="game-wrapper">
        <div
          class="game-card"
          v-for="(item, index) in gameList.slice(0, sliceNumber)"
          :key="index"
          @click="toDetail(item)"
        >
          <a
            :href="`/detail?id=${item.id}&category=${item.category}&description=${item.description}&instructions=${item.instructions}&tags=${item.tags}&thumb=${item.thumb}&title=${item.title}&url
=${item.url}`"
          >
            <img :src="item.thumb" alt="" />
          </a>
        </div>
      </div>

      <div
        class="main_ad"
        id="main_ad"
        style="display: flex; justify-content: center"
      >
        <!-- /22965457460/weenters.com/weenters-336x280-01 -->
        <div
          id="div-gpt-ad-1698312338971742-0"
          style="min-width: 336px; min-height: 280px"
        ></div>
      </div>
      <!-- <div class="game-wrapper">

        <div class="game-card" v-for="(item, index) in gameList.slice(sliceNumber, sliceNumbertwo)" :key="index" @click="toDetail(item)">
          <a :href="`/detail?id=${item.id}`">
            <img :src="item.thumb" alt="" />
          </a>
        </div>
      </div>   -->

      <div class="game-wrapper">
        <div
          class="game-card"
          v-for="(item, index) in gameList.slice(sliceNumber, gameList.length)"
          :key="index"
          @click="toDetail(item)"
        >
          <a
            :href="`/detail?id=${item.id}&category=${item.category}&description=${item.description}&instructions=${item.instructions}&tags=${item.tags}&thumb=${item.thumb}&title=${item.title}&url
=${item.url}`"
          >
            <img :src="item.thumb" alt="" />
          </a>
        </div>
      </div>

      <p style="text-align: center" v-show="isLoading">loading...</p>
    </div>
    <FooterBar />
  </div>
</template>

<script>
import axios from "axios";
import search from "../components/search.vue";
import Navbar from "../components/Navbar.vue";

import FooterBar from "../components/FooterBar.vue";
import CookieAccept from "../components/CookieAccept";
import { initGameList } from "../api";
import CookieAcceptVue from "@/components/CookieAccept.vue";
export default {
  data: () => ({
    gameList: [],
    nowPage: 1, //本页
    loading: false, //一步加载时的限制
    bottomHight: 50,
    isLoading: false,
    page: 1,
    num: 25,
    searchData: [],
    sliceNumber: 20,
    sliceNumbertwo: 15,
  }),
  components: {
    Navbar,
    FooterBar,
    CookieAccept,
    search,
  },
  methods: {
    clar(v) {
      this.searchData = [];
    },
    selectNameClick(v) {
      let d = `w_p=h5check&name=${v}&source=bestgame&page=1&size=10`;
      axios
        .post("https://gagame2.appclicking.com/api.php?req=GameSearch", d)
        .then((res) => {
          this.searchData = res.data.data;
        });
    },
    initData(query) {
      initGameList(query).then((res) => {
        this.gameList = res;
      });
    },
    handleScroll() {
      const wrap = document.querySelector(".game-wrapper");
      if (
        window.scrollY + window.innerHeight + 20 >
          wrap.offsetHeight + wrap.offsetTop &&
        !this.isLoading
      ) {
        this.isLoading = true;
        this.page++;
        initGameList({ num: 25, page: this.page })
          .then((res) => {
            // console.log(res)
            this.gameList = this.gameList.concat(res);
            this.isLoading = false;
          })
          .catch((e) => {
            this.isLoading = false;
          });
      }
    },
    toDetail(row) {
      console.log(row);
      // this.$router.push({
      //   name: "detail",
      //   query: { id: row.id },
      //   params: { info: row },
      // });
    },
  },
  created() {},
  mounted() {
    googletag.cmd.push(function () {
      googletag.display("div-gpt-ad-1698312338971742-0");
    });
    // =========== 首页 2- 3 行
    // ============ 首页 6 -7
    // window.googletag = window.googletag || {
    //   cmd: []
    // };

    // 根据屏幕尺寸显示个数
    if (window.innerWidth > 500) {
      this.sliceNumber = Math.floor(window.innerWidth / 141);
      this.sliceNumbertwo = this.sliceNumber * 2;
      this.initData({ page: this.page, num: 180 });
    } else {
      this.sliceNumber = 9;
      this.sliceNumbertwo = 15;
      this.initData({ page: this.page, num: 60 });
    }
    window.onresize = () => {
      if (window.innerWidth > 500) {
        this.sliceNumber = Math.floor(window.innerWidth / 141);
        this.sliceNumbertwo = this.sliceNumber * 2;
      } else {
        this.sliceNumber = 9;
        this.sliceNumbertwo = 15;
      }
    };
    // if (window.innerWidth > 1920) {
    //   this.sliceNumber = 36
    //   this.sliceNumbertwo = 72
    // } else if (window.innerWidth > 500) {
    //   this.sliceNumber = 20
    //   this.sliceNumbertwo = 40
    // } else {

    // }
    //添加滚动事件
    // window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    // window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.home-wrapper {
  background: var(--blue);
  padding-bottom: 60px;
}

.game-wrapper {
  /* width: 100vw; */
  /* min-height: 100vh; */
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 20px 0px 25px 15px;
  background: var(--blue);
}

.game-card {
  width: 120px;
  height: 80px;
  overflow: hidden;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  transition: 0.3s;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}

.game-card:hover {
  transform: rotate(2deg) scale(0.95);
}

.game-card img {
  width: 100%;
  height: auto;
}

@media (max-width: 600px) {
  .game-wrapper {
    width: 100vw;
    /* min-height: 100vh; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;
    background: var(--blue);
  }

  .game-card {
    width: 115px;
    height: 80px;
    overflow: hidden;
    border-radius: 8px;
    margin-right: 0;
    margin-bottom: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  }

  .game-card img {
    width: 100%;
    height: auto;
  }

  @media (min-width: 100px) and (max-width: 400px) {
    .game-card {
      width: 31.5%;
      height: 75px;
      overflow: hidden;
      border-radius: 8px;
      margin-right: 0;
      margin-bottom: 12px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    }

    .game-card img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
