import request from '@/utils/request';

const categoryMap = {
    '.IO': 1,
    Arcade: 5,
    Action: 4,
    Puzzles: 13,
    Racing: 14,
    Shooting: 15,
    Sports: 17,
};

/**
 * @description 请求所有数据
 * @param {Object} query
 * @return {Promise}
 * */
export const initGameList = (query) => {
    return request({
        query: {
            format: 0,
            category: 3,
            platform: 1,
            num: 50,
            page: 1,
            ...query,
        },
    });
};

/**
 * @description 分类请求
 * @param {Object} query
 * @param {String} category
 * @return {Promise}
 * */
export const initCategoryList = (query, category) => {
    return request({
        query: {
            format: 0,
            category: categoryMap[category] || 1,
            platform: 1,
            num: 25,
            page: 1,
            ...query,
        },
    });
};
export const GameSearch = (query, category) => {
    return request({
        url: '?req=GameSearch',
        query: {
            format: 0,
            category: categoryMap[category] || 1,
            platform: 1,
            num: 25,
            page: 1,
            ...query,
        },
    });
};