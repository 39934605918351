const INFO = "GAME_INFO";
const COOK = "COOKIE_A"

export const setGameInfo = (info) => {
    if(typeof info !== 'object') 
        return;
    localStorage.setItem(INFO,JSON.stringify(info));
}

export const getGameInfo = () => {
    const info = localStorage.getItem(INFO);
    if(!info)
        return false;
    return JSON.parse(info);
 }

 export const setCookieAccept = () => {
    localStorage.setItem(COOK,true);
 }  

 export const getCookieAccept = () => {
    return localStorage.getItem(COOK);
 }  